'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import Icon32Backward15 from 'src/assets/icons/Icon32Backward15'
import Icon32Forward15 from 'src/assets/icons/Icon32Forward15'
import Icon32Playlist from 'src/assets/icons/Icon32Playlist'
import IconPlay from 'src/assets/icons/IconPlay'
import { useSetAppAodStates } from '../store/store'
import { useAodConfigControl } from '@store/aodConfig'
import { useCallback } from 'react'
import { Box } from '@mui/joy'

interface AppAodPlayerControlsProps {
	paused: boolean
	currentTime: number
	updateCurrentTime: (value: number) => void
	handlePlay: () => void
}

const AppAodPlayerControls = ({
	paused,
	currentTime,
	updateCurrentTime,
	handlePlay,
}: AppAodPlayerControlsProps) => {
	const setAppAodStates = useSetAppAodStates()
	const { aodConfig, setAodConfig } = useAodConfigControl()
	const handlePlaylistOpen = () => {
		setAppAodStates((prev) => ({
			...prev,
			playlistOpen: true,
		}))
	}
	const handlePlaybackRate = useCallback(() => {
		const rateList = [1, 1.5, 2]
		const index = rateList.findIndex((rate) => rate === aodConfig.playbackRate)
		const playbackRate = rateList[(index + 1) % rateList.length]
		setAodConfig({ playbackRate })
	}, [aodConfig])
	return (
		<UIFlex
			flexDirection="row"
			alignItems="center"
			justifyContent="center"
			position="relative"
			sx={{
				'.button-speed': {
					width: pxToRem(44),
					height: pxToRem(26),
					textAlign: 'center',
					padding: `${pxToRem(3)} 0`,
					border: `1px solid ${color.colBlack}`,
					borderRadius: '9999px',
				},
			}}
		>
			<Box
				position="absolute"
				left={0}
				top="50%"
				sx={{
					transform: 'translateY(-50%)',
				}}
			>
				<button type="button" onClick={handlePlaylistOpen}>
					<Icon32Playlist />
					<UIText readonly>재생목록</UIText>
				</button>
			</Box>
			<UIFlex
				flexDirection="row"
				alignItems="center"
				sx={{
					'&>*:not(:first-child)': {
						marginLeft: pxToRem(16),
					},
				}}
			>
				<button type="button" onClick={() => updateCurrentTime(currentTime - 15)}>
					<Icon32Backward15 />
					<UIText readonly>15초 뒤로</UIText>
				</button>
				<button type="button" onClick={handlePlay}>
					<IconPlay isPlaying={!paused ?? true} />
					<UIText readonly>재생</UIText>
				</button>
				<button type="button" onClick={() => updateCurrentTime(currentTime + 15)}>
					<Icon32Forward15 />
					<UIText readonly>15초 앞으로</UIText>
				</button>
			</UIFlex>
			<Box
				position="absolute"
				right={0}
				top="50%"
				sx={{
					transform: 'translateY(-50%)',
				}}
			>
				<button type="button" className="button-speed" onClick={handlePlaybackRate}>
					<UIText
						component="span"
						fontSize={pxToRem(13)}
						color={color.colBlack}
						lineHeight={pxToRem(18)}
						fontWeight={500}
						sx={{
							display: 'inline-block',
						}}
					>
						{aodConfig.playbackRate?.toFixed(1)}x
					</UIText>
				</button>
			</Box>
		</UIFlex>
	)
}

export default AppAodPlayerControls
