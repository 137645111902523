'use client'

import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { Box, Slider } from '@mui/joy'
import { ProgramAodList, ProgramAodResponseVo } from '@schemas/non-auth'
import { useAodConfigControl } from '@store/aodConfig'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { useCallback, useEffect, useState } from 'react'
import IconPlay from 'src/assets/icons/IconPlay'
import IconPlayNext from 'src/assets/icons/IconPlayNext'
import IconVolume from 'src/assets/icons/IconVolume'

interface AodControlsProps {
	info: ProgramAodResponseVo
	playAod: ProgramAodList
	audio: HTMLAudioElement
	currentTime: number
	duration: number
	onPrev: () => void
	onNext: () => void
}
const AodControls = ({
	info,
	playAod,
	audio,
	currentTime,
	duration,
	onPrev,
	onNext,
}: AodControlsProps) => {
	const audioNode = audio
	const [paused, setPaused] = useState<boolean>(true)
	const { aodConfig, setAodConfig } = useAodConfigControl()
	const getTime = (time: number) => {
		return `${Math.floor(time / 60)}:${Math.floor(time % 60)
			.toString()
			.padStart(2, '0')}`
	}
	const updateVolume = (volume: number) => {
		setAodConfig({ volume })
	}
	const setMute = (mute: boolean) => {
		setAodConfig({ mute })
	}
	const handlePlay = useCallback(() => {
		if (paused) {
			audioNode.play()
		} else {
			audioNode.pause()
		}
	}, [paused])
	const onPause = () => {
		setPaused(true)
	}
	const onPlay = () => {
		setPaused(false)
	}
	const handlePlaybackRate = useCallback(() => {
		const rateList = [1, 1.5, 2]
		const index = rateList.findIndex((rate) => rate === aodConfig.playbackRate)
		const playbackRate = rateList[(index + 1) % rateList.length]
		setAodConfig({ playbackRate })
	}, [aodConfig])
	const handleMuted = useCallback(() => {
		if (audioNode.volume === 0) {
			audioNode.volume = aodConfig.volume as number
		} else {
			audioNode.volume = 0
		}
		setMute(audioNode.volume === 0)
	}, [audioNode, aodConfig])
	useEffect(() => {
		audioNode.addEventListener('pause', onPause)
		audioNode.addEventListener('play', onPlay)
		if (audioNode && aodConfig) {
			audioNode.playbackRate = aodConfig.playbackRate ?? 1
			audioNode.volume = aodConfig.mute ? 0 : (aodConfig.volume as number)
		}
		return () => {
			audioNode.removeEventListener('pause', onPause)
			audioNode.removeEventListener('play', onPlay)
		}
	}, [audioNode, aodConfig])
	return (
		<Box
			position={{ xs: 'static', md: 'absolute' }}
			left="50%"
			top="50%"
			sx={{
				marginRight: 'auto',
				transform: { xs: 'none', md: 'translate(-50%, -50%)' },
				'.button': {
					'&-prev, &-next': {
						'&:disabled': {
							opacity: 0.3,
							cursor: 'default',
						},
					},
					'&-prev': {
						transform: 'rotate(180deg)',
					},
					'&-speed': {
						width: pxToRem(44),
						height: pxToRem(26),
						textAlign: 'center',
						padding: `${pxToRem(3)} 0`,
						border: `1px solid ${color.colBlack}`,
						borderRadius: '9999px',
					},
				},
			}}
		>
			{/* 배속버튼 */}
			<Box
				position="absolute"
				right="100%"
				top="50%"
				sx={{ transform: 'translateY(-50%)' }}
				width="max-content"
				paddingRight={{
					md: pxToRem(16),
					xl: pxToRem(48),
				}}
			>
				<button type="button" className="button-speed" onClick={handlePlaybackRate}>
					<UIText
						component="span"
						fontSize={pxToRem(13)}
						color={color.colBlack}
						lineHeight={pxToRem(18)}
						fontWeight={500}
					>
						{aodConfig.playbackRate?.toFixed(1)}x
					</UIText>
				</button>
			</Box>
			{/* 플레이버튼 */}
			<UIFlex
				flexDirection="row"
				sx={{
					'&>*:not(:first-child)': {
						marginLeft: {
							xs: pxToRem(4),
							md: pxToRem(8),
							xl: pxToRem(16),
						},
					},
				}}
			>
				<button
					type="button"
					className="button-prev"
					disabled={!playAod?.preAodinsertDate}
					onClick={onPrev}
				>
					<IconPlayNext />
					<UIText readonly>이전</UIText>
				</button>
				<button type="button" className="button-play" onClick={handlePlay}>
					<IconPlay isPlaying={!paused} />
					<UIText readonly>재생</UIText>
				</button>
				<button
					type="button"
					className="button-next"
					disabled={!playAod?.nextAodinsertDate}
					onClick={onNext}
				>
					<IconPlayNext />
					<UIText readonly>다음</UIText>
				</button>
			</UIFlex>
			<UIFlex
				position="absolute"
				left="100%"
				top="50%"
				sx={{ transform: 'translateY(-50%)' }}
				width="max-content"
				flexDirection="row"
				alignItems="center"
				paddingLeft={{
					md: pxToRem(16),
					xl: pxToRem(48),
				}}
			>
				{/* 시간 */}
				<UIText
					display={{ xs: 'none', md: 'block' }}
					fontSize={pxToRem(14)}
					lineHeight={pxToRem(20)}
					fontWeight={500}
					color={color.colBlack}
					sx={{
						minWidth: pxToRem(getTime(duration).length * 16 + 15),
					}}
				>
					{getTime(currentTime)}/{getTime(duration)}
				</UIText>
				{/* 볼륨조정 */}
				<UIFlex
					display={{ xs: 'none', lg: 'flex' }}
					flexDirection="row"
					alignItems="center"
					marginLeft={{
						lg: pxToRem(16),
						xl: pxToRem(48),
					}}
					sx={{
						'&>*:not(:first-child)': {
							marginLeft: pxToRem(8),
						},
					}}
				>
					<button
						type="button"
						onClick={handleMuted}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: pxToRem(32),
							height: pxToRem(32),
						}}
					>
						<IconVolume isMuted={aodConfig.mute} />
						<UIText readonly>음소거</UIText>
					</button>
					<Slider
						step={0.1}
						min={0}
						max={1}
						value={aodConfig.mute ? 0 : aodConfig.volume}
						onChange={(e, value) => updateVolume(value as number)}
						sx={{
							width: {
								xs: pxToRem(72),
								xl: pxToRem(96),
							},
							'.MuiSlider': {
								'&-rail': {
									background: '#ccc',
									height: pxToRem(3),
								},
								'&-track': {
									height: pxToRem(3),
									background: color.colBlack,
								},
								'&-thumb': {
									width: pxToRem(10),
									height: pxToRem(10),
									border: 0,
									background: color.colBlack,
									'&::before': {
										display: 'none',
									},
								},
							},
						}}
					/>
				</UIFlex>
				{/* 외부링크 */}
				<UIFlex
					display={{ xs: 'none', md: 'flex' }}
					flexDirection="row"
					marginLeft={{
						xl: pxToRem(124),
						md: pxToRem(24),
						lg: pxToRem(28),
						xs: pxToRem(120),
					}}
					sx={{
						'&>*:not(:first-child)': {
							marginLeft: pxToRem(24),
						},
					}}
				>
					<UILink
						target="_blank"
						href="https://podcasts.apple.com/kr/podcast/jtbc-%EB%89%B4%EC%8A%A4%EB%A3%B8/id807339352"
					>
						<UIText
							fontSize={pxToRem(14)}
							lineHeight={pxToRem(20)}
							fontWeight={500}
							color={color.colBlack}
						>
							팟캐스트
						</UIText>
					</UILink>
					<UILink target="_blank" href="https://www.podbbang.com/channels/6949">
						<UIText
							fontSize={pxToRem(14)}
							lineHeight={pxToRem(20)}
							fontWeight={500}
							color={color.colBlack}
						>
							팟빵
						</UIText>
					</UILink>
				</UIFlex>
			</UIFlex>
		</Box>
	)
}

export default AodControls
